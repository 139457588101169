.button-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
}

.button-selector--option {
    cursor: pointer;
    user-select: none;
}

.button-selector--value-selected .button-selector--option-selected {
    cursor: default;
}

.button-selector--value-selected .button-selector--option {
    background-color: var(--color5-darker);
}

.button-selector--value-selected .button-selector--option-selected {
    background-color: var(--color3);
}

.button-selector--option {
    flex-grow: 1;
    text-align: center;
    margin: 3px;
    padding: 4px 9px;
    background-color: var(--color5);
    color: var(--color5-contrast);
    font-family: Helvetica;
    font-weight: bold;
    border-radius: 5px;
}


.button-selector--option:hover,
.button-selector--option:focus {
    background-color: var(--color5-lighter)
}

.button-selector--value-selected .button-selector--option-selected:hover,
.button-selector--value-selected .button-selector--option-selected:focus {
    background-color: var(--color3);
}