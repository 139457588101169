.transaction {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: var(--color2);
    color: var(--color2-contrast);
    padding: 3px 4px;
    font-family: monospace;
}

.transaction[data-type=TRANSFER] .transaction--amount {
    font-style: italic;
}.transaction[data-type=TRANSFER] .transaction--amount::before {
    content: '-';
}

.transaction[data-person=Julian] {
    background-color: var(--color1);
    color: var(--color1-contrast);
}
.transaction[data-person=Benedicte] {
    background-color: var(--color2);
    color: var(--color2-contrast);
}

.transaction--timestamp {
    opacity: .6;
    font-size: 80%;
    white-space: nowrap;
    padding-right: 10px;
}

.transaction:nth-child(odd) {
    opacity: .8;
}