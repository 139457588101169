.balance {
    width: 100%;
    border: 1px solid var(--color3);
    background-color: var(--color3);
    color: var(--color3-contrast);
    padding: 10px;
    font-size: 20px;
    text-align: center;
}


.balance--person {
    font-weight: bold;
}
.balance--amount {
    font-weight: bold;
}