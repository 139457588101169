.transaction-form {
    margin: 0 0 20px 0;
    position: relative;
}

.transaction-form--cancel {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

.transaction-form--cancel:hover,
.transaction-form--cancel:focus {
    color: white;
    background-color: var(--color1);
}

.transaction-form--input,
.transaction-form--submit,
.transaction-form--cancel{
    font-size: 1em;
    padding: 4px 7px;
    outline: none;
    border: none;
    border-radius: 5px;
}

.transaction-form--submit {
    margin-left: 10px;
    cursor: pointer;
}

.transaction-form--submit:hover,
.transaction-form--submit:focus {
    color: white;
    background-color: var(--color1);
}

.transaction-form--input-amount {
    width: 100px;
}
.transaction-form--input-comment {
    width: 200px;
}

.transaction-form .button-selector {
    display: inline-block;
}

.transaction-form--header {
    margin: 0 0 10px 0;
}

@media only screen and (max-width: 700px) {
    .transaction-form--input,
    .transaction-form--submit,
    .button-selector--option {
        width: 100%;
        font-size: 1.6em;
        margin: 5px 0;
    }

    .transaction-form .button-selector {
        width: 100%;
        display: flex;
    }

    .transaction-form--register {

    }

    .transaction-form {
        display: flex;
        flex-direction: row;
    }

    .transaction-form--cancel {
        position: relative;
        margin-top: 5px;
        width: 100%;
    }
}

.transaction-form--submit-validation-error {
    animation: shake 0.1s linear 3;
}

@keyframes shake {
    50% {
        transform: translateX(-10px);
    }
    80% {
        transform: translateX(10px);
    }
    0%,100% {
        transform: translateX(0px);
    }
}