* {
    box-sizing: border-box;
}

:root {
    --color1: #E4572E;
    --color1-contrast: white;

    --color2: #17BEBB;
    --color2-contrast: black;

    --color3: #FFC914;
    --color3-contrast: black;

    --color4: #2E282A;
    --color4-contrast: white;

    --color5: #76B041;
    --color5-darker: #59862f;
    --color5-lighter: #97d062;
    --color5-contrast: black;
}

body {
    background-color: var(--color4);
    color: var(--color4-contrast);
    font-family: Arial;
}