.header {
    width: 100%;
    font-size: 28px;
    padding: 5px 20px;

    background-color: var(--color5);
    color: var(--color5-contrast);

    display: flex;
    justify-content: space-between;
}

.header--auth {
    font-family: monospace;
    font-size: .7em;

    display: flex;
    align-items: center;
}

.header--username {
    margin-right: 20px;
}

.header--logout {
    cursor: pointer;
    background: none;
    outline: none;
    border: none;
    font-size: 18px;
}

.header--logout:hover,
.header--logout:active {
    text-decoration: underline;
}

.main {
    padding: 20px;
}