
.login {
    margin: 20px auto 0 auto;
    max-width: 350px;

    background-color: var(--color1);

    padding: 5px 20px 20px 20px;
    border-radius: 10px;
}

.login h3 {
    text-align: center;
}

.login--username, .login--password {
    width: 100%;
    padding: 7px 10px;
    border-radius: 10px;
    outline: none;
    border: none;
    margin-bottom: 10px;
    font-size: 1.5em;
}

.login--submit {
    margin-top: 15px;
    border-radius: 10px;
    width: 100%;
    height: 40px;
    text-transform: uppercase;
    font-size: 1.5em;
    background-color: var(--color3);
    color: var(--color3-contrast);
    border: none;
    outline: none;
}

.login--submit:hover,
.login--submit:focus,
.login--submit:active {
    color: white;
    cursor: pointer;
}

.login--error {
    color: var(--color1-contrast);
    padding: 0 5px 5px 5px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    display: inline-block;
}